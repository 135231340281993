<div class="fade-in-content">
    <p class="article-header">
        Цифровизация ТОИР с применением нормирования труда
    </p>
    <p class="article-text">
Проблема управления затратами в современных условиях актуальна для большинства Российских промышленных предприятий. Перед руководством встал вопрос оптимизации затрат на осуществление производственной деятельности, основную часть которой составляет выполнение программ по ремонту и техническому обслуживанию оборудования. Определяющим фактором рационального использования средств, выделяемых для этих целей, является достоверное определение стоимости ремонта.
    </p>
    <p class="article-text">
Эффективность и надежность функционирования оборудования зависит от его технического состояния. Современное оборудование имеет достаточно высокие расчетные показатели надежности. Однако в процессе эксплуатации под воздействием различных факторов, условий и режимов работы исходное состояние оборудования непрерывно ухудшается, снижается эксплуатационная надежность и увеличивается опасность возникновения отказов.
    </p>
    <p class="article-text">
Общепринятые стратегии планово-предупредительных ремонтов не учитывали индивидуальные особенности эксплуатируемого оборудования, отсутствовала единая база технологического оборудования. Процесс сбора и обработки данных о техническом состоянии оборудования не был автоматизирован. Учет неисправных узлов и агрегатов, вынужденных простоев и планируемых ремонтов, резерв расходных материалов и запасных частей, расчет потребности в ремонтном персонале и эксплуатации используемых в процессе ремонта машин и механизмов осуществлялся вручную преимущественно в электронных таблицах, как и графики календарного планирования ремонтов. Все это не позволяло прогнозировать отказы оборудования и своевременно выполнять профилактические мероприятия.
    </p>
    <p class="article-text">
Основным принципом новой технологии управления ремонтами оборудования является внедрение IT-системы для ТОиР, основанной на индивидуальном наблюдении за реальными изменениями фактического технического состояния оборудования в процессе эксплуатации. Это дает возможность объективно оценивать состояние оборудования в целом, что в свою очередь позволяет планировать ремонт не по графику, а по потребности, и в объеме, необходимом и достаточном для устранения конкретной неисправности.
    </p>
    <p class="article-text">
Для управления ремонтами оборудования в IT-системе необходимо сформировать базу данных оборудования (БДО) для структурирования по однотипности, техническим характеристикам, исключив дублирование записей.
    </p>
    <div class="row justify-content-center pt-3 px-0 pb-4">
        <img style="width:90%" src="../../images/services/asutp1.jpg" />
    </div>
    <p class="article-text">
На основании базы данных оборудования (БДО) формируется иерархическая структура оборудования для выбора планов и стратегий ремонтов.
    </p>
    <div class="row p-0 m-0 w-100 mb-3">
      <div class="col-6 m-0 p-0">
        <img class="pl-1 pl-2 pr-4 w-100 float-left" src="../../images/services/asutp2.jpg" />
      </div>
      <div class="col-6 m-0 p-0">
        <p class="p4">
Иерархическая структура оборудования выстраивается с учетом:
        </p>
        <div class="p4">
          <ul>        
<li>схем производственного процесса на площадке;</li>
<li>схем производственных процессов цехов;</li>
<li>однотипности оборудования;</li>
<li>принадлежности в технологическом процессе;</li>
<li>состава Механизмов / Узлов / Деталей, объекта ремонта;</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pt-3 px-0 pb-4">
        <img style="width:90%" src="../../images/services/asutp3.png" />
    </div>

    <p class="article-text">
На основании иерархической структуры формируются Технологические карты (База данных нормативов) ТОиР оборудования. Для разработки нормативов необходимо для каждого Объекта ремонтного воздействия (механизм, узел, деталь) определить перечень ремонтных операций, которые требуется совершить при осмотре, техническом обслуживании и непосредственно ремонте.
    </p>
    <p class="article-text">
Затем необходимо структурировать последовательность ремонтных операций по видам ремонта (КР, ТР, ППР, СО, ТО и т.д.).
    </p>
    <p class="p3">
Нормативы на ремонт оборудования содержат:
    </p>
    <div class="p4">
      <ul>        
<li>наименование работ (операций ремонта) в порядке их технологической последовательности;</li>
<li>состав работ по каждой операции ремонта с описанием технологического процесса, включающего последовательность выполнения работы и применение средств механизации;</li>
<li>состав и квалификация ремонтного звена для выполнения операций ремонта оборудования;</li>
<li>расчет трудоемкости (чел.-ч.) выполнения ремонтных операций и количества временных затрат, необходимых для выполнения ремонта оборудования, включающих в себя подготовительно-заключительные работы и технологические перерывы;</li>
<li>расчет эксплуатации (маш.-ч.) машин и механизмов, требующихся для выполнения ремонтных операций, с указанием полной номенклатуры машин и механизмов, применяемых при ремонте оборудования, с учетом использования современных видов механизмов и спецтехники, обеспечивающих высокую производительность труда;</li>
<li>номенклатура и количество материалов, используемых при ремонте, с учетом необходимых потерь и отходов, возникающих при ремонте оборудования;</li>
      </ul>
    </div>   
    
    <p class="p3">
Внедрение Технологических карт по ТОиР оборудования позволит развить следующие направления:
    </p>
    <div class="p4">
      <ul>        
<li>«Надежность» - Управление стратегиями обслуживания оборудования, анализ рисков и отказов оборудования;</li>
<li>«Планирование» - Формирование годовых графиков ТОиР оборудования по видам ремонтных воздействий, формирование стратегий ремонта;</li>
<li>«Отчетность» - Формирование аналитических отчетов по каждому направлению ТОиР;</li>
<li>«Исполнитель» - Сокращение ремонтных затрат без ухудшения ключевых КПЭ;</li>
      </ul>
    </div>

    <p class="p4 ml-4">
        <a class="link-inline" target="_blank" href="files/ТОиР-Корпус дробилки (базовые узлы).xlsx">Пример технологической карты 1</a>
    </p>
    <p class="p4 ml-4">
        <a class="link-inline" target="_blank" href="files/ТОиР_Выключатель элегазовый 330 кВ.xlsx">Пример технологической карты 22</a>
    </p>
</div>
